import { FormControl, FormLabel, useMediaQuery } from '@chakra-ui/react';
import { Select, ActionMeta } from 'chakra-react-select';
import React, { useState } from 'react';

interface PageSizeSelectionProps {
    options: { value: number; label: string }[];
    onPageSizeChange: (size: number) => void;
    w?: number;
    h?: number;
    mt?: number;
    mb?: number;
    ml?: number;
    mr?: number;
}

const PageSizeSelection: React.FC<PageSizeSelectionProps> = ({ options, onPageSizeChange, w, h, mt, mb, ml, mr  }) => {
    const [isMobile] = useMediaQuery('(max-width: 1200px)');
    const [selectedSize, setSelectedSize] = useState<{ value: number; label: string }>(options[0]);

    const handleChange = (newValue: { value: number; label: string; }, actionMeta: ActionMeta<{ value: number; label: string; }>) => {
        const newSize = newValue.value;
        setSelectedSize(newValue);
        onPageSizeChange(newSize);
    };

    return (
       <FormControl 
            {...(isMobile ? { 
                w: '100%', 
                mb: 4
            } : (
                { 
                    mt: mt ?? 'auto',
                    w: w ?? 'auto',
                    h: h ?? 'auto',
                    mb: mb ?? 4,
                    ml: ml ?? 'auto',
                    mr: mr ?? 'auto' 
                }
            ))}
            >
            <FormLabel htmlFor='page-size-select' fontWeight="bold">
                Page Size
            </FormLabel>
            <Select 
                id='page-size-select' 
                value={selectedSize} 
                onChange={handleChange}
                options={options}
            />
        </FormControl>
    );
};

export default PageSizeSelection;