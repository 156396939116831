// Chakra imports
import { Box, Portal, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RootState } from 'store/store';
import useAuth from '../../hooks/auth';
import { FeatureRoute } from '../../models/routes';
import routes from 'routes';
import { AuthenticationResponse } from '../../models/auth';
import { get } from 'http';


// Custom Chakra theme
export default function ClientLayout(props: { [x: string]: any }) {
  const [userProfile, setUserProfile] = useState<AuthenticationResponse | null>(null);
  const profile = localStorage.getItem('authentication') ? JSON.parse(localStorage.getItem('authentication') || '{}') : null;
  const product = useSelector((state: RootState) => state.product.userProduct);
  const { getUserActiveSubscriptions } = useAuth();
  const [subscriptionLevel, setSubscriptionLevel] = useState<string | null>(null);

  // useEffect(() => {
  //   const initialKey = localStorage.getItem('apiKey');
  //   console.info(initialKey);
  // if (profile?.userId) {
  // 	setUserItems(true)
  // }else {
  // 	setUserItems(false)
  // }
  //   if (initialKey?.includes('sk-') && apiKey !== initialKey) {
  //     setApiKey(initialKey);
  //   }
  // }, [apiKey]);

  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/compliance/full-screen-maps';
  };
  const getActiveRoute = (routes: FeatureRoute[]): string => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: FeatureRoute[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes: FeatureRoute[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      // console.info('+=+=+=');
      // console.info(routes[i].layout + routes[i].path);
      // console.info(window.location.href);
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        // console.info(routes[i].name);
        return routes[i].name;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes: FeatureRoute[]): any => {;
    const subscriptionLevel = getSubscriptionLevel() || sessionStorage.getItem('subscriptionLevel');
    return routes.map((route: FeatureRoute, key: any) => {
      if (route.layout === '/compliance') {  
      switch (subscriptionLevel) {
        case 'Premium':
          if (route.plan === 'Premium' || route.plan === 'Basic') {
            return includeRoute(route, key);
          }
          break;
        case 'Enterprise':
          if (route.plan === 'Enterprise' || route.plan === 'Premium' || route.plan === 'Basic') {
            return includeRoute(route, key);
          }
          break;
        default:
          if (route.plan === 'Basic') {
            return includeRoute(route, key);
          }
          break;
      }
      } else {
        return null;
      }
    });
  };

  const includeRoute = (route: FeatureRoute, key: any) => {
    return (
      <Route
        path={route.layout + route.path}
        component={route.component}
        key={key}
      />
    );
  }
  const getSubscriptionLevel = () => {
    return subscriptionLevel;
  }
  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  // const sliceIndex = !product?.hasProduct && (userProfile && userProfile?.userId) ? 50 : 3;

  useEffect(() => {
    const delay = 1000;
    const timer = setTimeout(() => {
      if (profile?.userId) {
        try {
          getUserActiveSubscriptions(profile.userId).then((response) => {
            const subLevel = response?.reduce((highestPlan: string, plan: any) => {
              if (plan.status === "Active") {
                if (plan.plan_name === 'Enterprise') return 'Enterprise';
                if (plan.plan_name === 'Premium' && highestPlan !== 'Enterprise') return 'Premium';
                if (plan.plan_name === 'Basic' && highestPlan !== 'Enterprise' && highestPlan !== 'Premium') return 'Basic';
              }
              setSubscriptionLevel(highestPlan);
              return highestPlan;
            }, '');
            if (subLevel) {
              setSubscriptionLevel(subLevel);
            }
          });
        }
        catch (error) {
          console.error('Error fetching user active subscriptions:', error);
        }
      }
    }, delay);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        {profile?.userId ? (
          <Sidebar
            routes={routes.slice(0, 50)}
            display="none"
            {...rest}
          /> ) : (
          <Sidebar
            routes={routes.slice(0, 3)}
            display="none"
            {...rest}
          />
        )
        }
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={'Nucomply UI Dashboard PRO'}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: '20px', md: '30px' }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/" to="/compliance/chat" />
              </Switch>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
