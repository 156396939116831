import React from 'react';
import { Icon } from '@chakra-ui/react';
import { MdNotificationsNone } from 'react-icons/md';
import NotificationCount from '../notificationCount/notificationCount';
import { Link } from 'react-router-dom';

interface NotificationBellProps {
    showCount?: boolean;
}

const NotificationBell: React.FC<NotificationBellProps> = ({ showCount }) => {
    return (
        <Link to="/compliance/regulatory-change-review">
            <Icon as={MdNotificationsNone} width="20px" height="20px" color="inherit" />
            {showCount === true && <NotificationCount />}
        </Link>
    );
};

export default NotificationBell;