// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom Components
import PropTypes from 'prop-types';
// Assets
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import useAuth from 'hooks/auth';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import { setAuthentication } from 'store/reducers/auth';
import { clearMessages } from 'store/reducers/messages';
import { RootState } from 'store/store';
export default function HeaderLinks(props: { secondary: boolean }) {
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  // const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  // const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  // const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  // const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  // const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

  const dispatch = useDispatch();
  const history = useHistory();
  const { logOut, login, editProfile } = useAuth();

  const handleLogout = () => {
    localStorage.removeItem('authentication');
    sessionStorage.removeItem('product');
    sessionStorage.removeItem('plan');
    sessionStorage.removeItem('chat');
    dispatch(setAuthentication(undefined));
    dispatch(clearMessages());
    logOut();
  };

  const handleProductSetting = () => {
    history.push('/compliance/profile-overview');
  };

  const handleProfileSetting = () => {
    // if (!profile?.idToken || !profile?.userId) {
    //   login();
    //   return;
    // }
    editProfile();
  };

  const handleLogIn = () => {
    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }
  };

  const profile = useSelector((state: RootState) => state.auth.authentication);

  return (
    <Flex
      display={{ sm: 'none', xl: 'flex' }}
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      {/* <SearchBar
				mb={() => {
					if (secondary) {
						return { base: '10px', md: 'unset' };
					}
					return 'unset';
				}}
				me='10px'
				borderRadius='30px'
			/> */}
      <SidebarResponsive routes={routes} />
      

      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={toggleColorMode}
      >
        <Icon
          me="10px"
          h="18px"
          w="18px"
          color={navbarIcon}
          as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
        />
      </Button>
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color="white"
            // name='User'
            bg="#0680ff"
            size="sm"
            w="40px"
            h="40px"
            src={profile?.user?.picture} // Set the src to the user's picture URL
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex flexDirection="column" p="10px" zIndex={15}>
            {!profile?.idToken && (
              <>
                <Text
                  ps="10px"
                  pt="16px"
                  pb="10px"
                  w="100%"
                  borderBottom="1px solid"
                  borderColor={borderColor}
                  fontSize="sm"
                  fontWeight="700"
                  color={textColor}
                  onClick={handleLogIn}
                >
                  👋&nbsp; Hey, Please login
                </Text>
                <MenuItem
                  _hover={{ bg: 'none' }}
                  _focus={{ bg: 'none' }}
                  color="blue.400"
                  borderRadius="8px"
                  px="14px"
                  onClick={handleLogIn}
                >
                  <Text fontSize="sm">Log In</Text>
                </MenuItem>
              </>
            )}
            {profile?.idToken && (
              <div>
                {/* <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius='8px' px='14px'
							onClick={handleProfileSetting}>
							<Text fontSize='sm'>Profile Settings</Text>
						</MenuItem> */}
                <Text
                  ps="10px"
                  pt="16px"
                  pb="10px"
                  w="100%"
                  borderBottom="1px solid"
                  borderColor={borderColor}
                  fontSize="sm"
                  fontWeight="700"
                  color={textColor}
                >
                  &nbsp; {profile?.userEmail}
                </Text>
                <MenuItem
                  _hover={{ bg: 'none' }}
                  _focus={{ bg: 'none' }}
                  onClick={handleProductSetting}
                  borderRadius="8px"
                  px="14px"
                >
                  <Text fontSize="sm">Institution Profile</Text>
                </MenuItem>
                <MenuItem
                  _hover={{ bg: 'none' }}
                  _focus={{ bg: 'none' }}
                  color="red.400"
                  borderRadius="8px"
                  px="14px"
                  onClick={handleLogout}
                >
                  <Text fontSize="sm">Log out</Text>
                </MenuItem>
              </div>
            )}
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
