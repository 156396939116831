import React from 'react';
import { RepeatIcon } from '@chakra-ui/icons';
import './resetButton.css';

interface ResetButtonProps {
    handleReset: () => void;
}

const ResetButton: React.FC<ResetButtonProps> = ({ handleReset }) => {
    return (
        <RepeatIcon className="resetButton" onClick={handleReset} cursor="pointer" />
    );
};

export default ResetButton;
