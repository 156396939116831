"use client";
/*eslint-disable*/

import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import useAuth from "hooks/auth";
import ComingSoonMessage from "../CommingSoon";
import RegulatoryTable from "./table";
import { PolicyTableData, TableData } from "../../../../models/regChangeModels";
import {
  setRegulatoryChangesCheckpoint,
  getRegulatoryChanges,
  getRegulatoryChangeList
} from "../../../../services/regChangesService";
import DrillDown from "./drillDown";
import {
  setCurrentFile,
  setCurrentFileOpen,
  setNewRegsCount,
} from "../../../../store/reducers/regChange";
import eventBus from "../../../../utils/eventBus";
import PageSelect from "../../../../components/pageSelect/pageSelect";

export default function RegulatoryChangeReview() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilter] = useState("");
  const [selectedFile, setSelectedFile] = useState<number | null>(null);
  const [showFile, setShowFile] = useState(false);
  const currentFileOpen = useSelector(
    (state: RootState) => state.regChange.currentFileOpen
  );
  const partner = JSON.parse(
    '{"id":3,"name":"federal_banking_compliance"}'
  ).name;
  const persona = JSON.parse('{"id":5,"name":"federal_content_expert"}').name;
  const userId =
    JSON.parse(localStorage.getItem("authentication") || "{}").userId || "";
  const [newChanges, setNewChanges] = useState<number>(0);
  const { ensureValidToken } = useAuth();
  const handleTabClick = (tab: number) => {
    setSelectedTab(tab);
  };
  const [regulatoryChanges, setRegulatoryChanges] = useState<TableData | null>(
    null
  );
  const [policyChanges, setPolicyChanges] = useState<PolicyTableData | null>(
    null
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const resetComponent = () => {
      localStorage.removeItem("currentChange");
      localStorage.removeItem("currentChangeOpen");
      localStorage.removeItem("currentRegChange");
      setSelectedFile(null);
      setShowFile(false);
      dispatch(setCurrentFile(null));
      dispatch(setCurrentFileOpen(false));
    };
    eventBus.on("resetRegChanges", resetComponent);
    return () => {
      eventBus.off("resetRegChanges", resetComponent);
    };
  }, []);

  useEffect(() => {
    if (currentFileOpen) {
      setShowFile(true);
    } else {
      setShowFile(false);
    }
  }, [currentFileOpen]);

  useEffect(() => {
    fetchRegulatoryChanges();
  }, [filter, pageNo, pageSize]);

  const fetchRegulatoryChanges = async () => {
    const token = await ensureValidToken(localStorage.getItem("token"));
    try {
      setLoading(true);
      const response = await getRegulatoryChangeList(
        token,
        partner,
        persona,
        userId,
        pageNo,
        pageSize,
        filter
      )
        .then((response) => {
          if (response) {
            calculateNewRegs(response);
          }
          return response;
        })
        .finally(() => {
          setLoading(false);
        });
      setRegulatoryChanges(response);
      await setRegulatoryChangesCheckpoint(token, userId).finally(() => {
        console.info("Regulatory changes checkpoint set");
      });
    } catch (error) {
      console.error("Error fetching regulatory changes:", error);
    }
  };


  const calculateNewRegs = async (response: TableData) => {
    let changes = 0;
    for (let i = 0; i < response.data.length; i++) {
      if (response.data[i].checked === false) {
        changes++;
      }
    }
    setNewChanges(changes);
    dispatch(setNewRegsCount(changes));
  };

  const handleFilter = (filterCriteria: string) => {
    setFilter(filterCriteria);
  };

  const handlePageNoChange = (page: number) => {
    setPageNo(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageNo(1);
    setPageSize(size);
  };

  const handleFileSelected = (id: number, selected: boolean) => {
    setSelectedFile(id);
    setShowFile(selected);
    dispatch(setCurrentFile(id.toString()));
    dispatch(setCurrentFileOpen(selected));
  };

  const handleFileClose = () => {
    setShowFile(false);
  };

  
  useEffect(() => {
    setTotalPages(Math.ceil(regulatoryChanges?.count_doc / pageSize) || 1);
  }, [pageSize,regulatoryChanges]);

  return (
    <div style={{ marginTop: "100px" }}>
      <Flex
        w="100%"
        direction="column"
        position="relative"
        mt={{ base: "20px", md: "0px", xl: "0px" }}
      >
        {!showFile && (
          <Tabs
            variant="soft-rounded"
            colorScheme="teal"
            isFitted
            onChange={(index) => handleTabClick(index)}
          >
            <TabList>
              <Tab>Recent Changes</Tab>
              <Tab>Policy Review</Tab>
              <Tab>Change Scenario</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <RegulatoryTable
                  dataType="regulatory"
                  data={regulatoryChanges}
                  pageSize={pageSize}
                  pageNo={pageNo}
                  loading={loading}
                  onFilter={handleFilter}
                  onPageNoChange={handlePageNoChange}
                  onPageSizeChange={handlePageSizeChange}
                  onFileSelect={handleFileSelected}
                />
                {pageSize >= 50 ? (
                  <PageSelect
                    currentPage={pageNo}
                    totalPages={totalPages}
                    onPageChange={handlePageNoChange}
                    loading={false}
                  />
                ) : null}
              </TabPanel>
              <TabPanel>
                <ComingSoonMessage />
              </TabPanel>
              <TabPanel>
                <ComingSoonMessage />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
        {showFile && <DrillDown id={selectedFile} onClose={handleFileClose} />}
      </Flex>
    </div>
  );
}
