/* eslint-disable */
/*!
                              
=========================================================
* Nucomply UI - v1.1.0
=========================================================

* Product Page: https://www.nuarca.com/
* Copyright 2022 Nucomply UI (https://www.nuarca.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text, useColorModeValue,
  useToast
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { setAuthentication } from "store/reducers/auth";
import { authLogin } from "utils/login";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const dispatch = useDispatch();

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isAuthLoading, setAuthLoading] = useState(false);
  const toast = useToast();
  const history = useHistory();

  const login = async (userName: string, password: string) => {
    setTimeout(async () => {
        const lastLoginAttempt = localStorage.getItem('lastLoginAttempt');
        const now = new Date();

        if (!lastLoginAttempt || (now.getTime() - new Date(lastLoginAttempt).getTime()) > 20000) {
          localStorage.setItem('lastLoginAttempt', now.toString());
          setAuthLoading(true);
          await authLogin(userName, password)
          .then((res) => {
            dispatch(setAuthentication(res as any));
            toast({
            title: 'Login successful',
            status: 'success',
            duration: 3000,
            isClosable: true,
            });
          })
          .catch((error) => {
            toast({
            title: 'Login failed',
            description: error.message || 'An error occurred',
            status: 'error',
            duration: 3000,
            isClosable: true,
            });
          })
          .finally(() => {
            setAuthLoading(false);
            setUserName('');
            setPassword('');
            history.push('/');
          });
        } else {
          toast({
          title: 'Please wait before trying again',
          status: 'warning',
          duration: 3000,
          isClosable: true,
          });
        }
    }, 500)
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    login(userName, password);
    // Here you can submit the email and password securely
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          
          <Flex align='center' mb='25px'>
            
          </Flex>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' mb='8px'>
                User name<Text color='brandStars'>*</Text>
              </FormLabel>
              <Input
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                isRequired
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='hasan'
                mb='24px'
                fontWeight='500'
                size='lg'
              />
              <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500'>
                Password<Text color='brandStars'>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  isRequired
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={showPassword ? 'text' : 'password'}
                  variant='auth'
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color='textColorSecondary'
                    _hover={{ cursor: 'pointer' }}
                    as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handlePasswordVisibility}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent='space-between' align='center' mb='24px'>
                <FormControl display='flex' alignItems='center'>
                  <Checkbox id='remember-login' colorScheme='brandScheme' me='10px' />
                  <FormLabel htmlFor='remember-login' mb='0' fontWeight='normal' fontSize='sm'>
                    Keep me logged in
                  </FormLabel>
                </FormControl>
                <NavLink to='/auth/forgot-password'>
                  <Text color='textColorBrand' fontSize='sm' w='124px' fontWeight='500'>
                    Forgot password?
                  </Text>
                </NavLink>
              </Flex>
              <Button type='submit' fontSize='sm' variant='brand' fontWeight='500' w='100%' h='50' mb='24px'>
                Sign In
              </Button>
            </FormControl>
          </form>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/auth/sign-up'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
