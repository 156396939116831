import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { getRegulatoryChangeDetail } from '../../../../services/regChangesService';
import Chat from '../../ask-question/chat';
import useAuth from 'hooks/auth';

interface DrillDownProps {
    id: number;
    onClose: () => void;
}

const DrillDown: React.FC<DrillDownProps> = ({id, onClose}) => {
    const setCurrentChange = () => {
        localStorage.setItem('currentChange', id?.toString());
        localStorage.setItem('currentChangeOpen', 'true');  
    }

    useEffect(() => {
        setCurrentChange();
    }, [id]);

    return (
    <Box p={16} maxW="15xl" mx="auto">
      <Flex
        w="100%"
        direction="column"
        position="relative"
      >
        <Chat/>
      </Flex>          

    </Box>
    );
};

export default DrillDown;