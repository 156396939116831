import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import store from 'store/store';
import theme from 'theme/theme';
import MainPage from 'mainPage';
import { AbortControllerProvider } from './hooks/AbortControllerContext';

const App = () => (
  <AbortControllerProvider>
    <ChakraProvider theme={theme}>
        <Provider store={store}>
          <MainPage />
        </Provider>
    </ChakraProvider>
  </AbortControllerProvider>
);

export default App;