import { Plan, SubscriptionPlans } from './../../models/plans';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface subscriptionState {
    availablePlans: Plan[] | undefined;
    subscriptionPlans: SubscriptionPlans | undefined;
    subscription: string | undefined;
}

const initialState: subscriptionState = {
    availablePlans: undefined,
    subscriptionPlans: undefined,
    subscription: undefined,
};

const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        setAvailablePlans: (state, action: PayloadAction<Plan[]>) => {
            state.availablePlans = action.payload;
        },
        setSubscriptionPlans: (state, action: PayloadAction<SubscriptionPlans>) => {
            state.subscriptionPlans = action.payload;
        },
        setSubscription: (state, action: PayloadAction<string>) => {
            state.subscription = action.payload;
        }
    },
});

export const { setAvailablePlans, setSubscription, setSubscriptionPlans } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;