import React, { useEffect, useMemo, useState } from "react";
import { TableData } from "../../../../models/regChangeModels";
import "./table.css";
import PageSelect from "../../../../components/pageSelect/pageSelect";
import PageSizeSelection from "../../../../components/pageSizeSelection/pageSizeSelection";
import { BsLink } from "react-icons/bs";
import SearchBox from "../../../../components/searchBox/searchBox";
import {
  Flex,
  Spinner,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import useDebounce from "../../../../utils/debounce";

interface RegulatoryTableProps {
  dataType: string;
  data: TableData;
  pageSize: number;
  pageNo: number;
  loading: boolean;
  onFilter: (filterCriteria: string) => void;
  onPageNoChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  onFileSelect: (id: number, selected: boolean) => void;
}

const RegulatoryTable: React.FC<RegulatoryTableProps> = ({
  dataType,
  data,
  pageSize,
  pageNo,
  loading,
  onFilter,
  onPageNoChange,
  onPageSizeChange,
  onFileSelect,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 1200px)");
  const [isMediumScreen] = useMediaQuery(['(max-width: 1720px)']);
  const regulatoryTableData: any = Array.isArray(data?.data)
    ? data?.data
    : [];

  const [totalPages, setTotalPages] = useState(1);

  const [followedLinks, setFollowedLinks] = useState<string[]>(() => {
    const storedLinks = localStorage.getItem("followedLinks");
    return storedLinks ? JSON.parse(storedLinks) : [];
  });

  const tableBg = useColorModeValue("white", "navy.900");
  const tableColor = useColorModeValue("black", "white");
  const headerBg = useColorModeValue("gray.100", "navy.800");
  const headerColor = useColorModeValue("black", "white");
  const linkColor = useColorModeValue("blue.500", "blue.300");

  useEffect(() => {
    setTotalPages(Math.ceil(data?.count_doc / pageSize) || 1);
  }, [pageSize,data]);

  const pageSizeOptions = [
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 75, label: "75" },
    { value: 100, label: "100" },
  ];

  const handleRowClick = (filteredData: any) => {
    setFollowedLinks((prevFollowedLinks) => {
      const updatedLinks = [...prevFollowedLinks, filteredData.id.toString()];
      localStorage.setItem("followedLinks", JSON.stringify(updatedLinks));
      return updatedLinks;
    });
    onFileSelect(filteredData.id, true);
    localStorage.setItem("currentRegChange", JSON.stringify(filteredData));
  };

  const generateHeaders = (data: any[]) => {
    if (data.length === 0) return [];
    switch (dataType) {
      case "regulatory":
        return regulatoryHeaders;
      default:
        return [];
    }
  };

  const regulatoryHeaders = ["Change", "Regulator", "Type", "Date", "Jurisdiction", "Impact", "Status", "id", "impact_reason", "document_name", "new_document_url"];

  const mapDataToHeaders = (data: any[], headers: string[]) => {
    return data.map((item) => {
      const mappedItem: { [key: string]: any } = {};
      headers.forEach((header) => {
        switch (header) {
          case "Change":
            mappedItem[header] = item?.display_name;
            break;
          case "Regulator":
            mappedItem[header] = Array.isArray(item.regulator)
              ? item.regulator_name.join(", ")
              : item.regulator
              ? item.regulator.replace(/[\[\]"]/g, '')
              : '';
            break;
          case "Type":
            mappedItem[header] = item.type;
            break;
          case "Date":
            mappedItem[header] = item.created_at;
            break;
          case "Jurisdiction":
            mappedItem[header] = Array.isArray(item.jurisdiction)
              ? item.jurisdiction.join(", ")
              : item.jurisdiction
              ? item.jurisdiction.replace(/[\[\]"]/g, '')
              : '';
            break;
          case "Impact":
            mappedItem[header] = item.impact_level;
            break;
          case "Status":
            mappedItem[header] = item.checked ? "Reviewed" : "Pending";
            break;
          case "id":
            mappedItem[header] = item.id;
            break;
          case "impact_reason":
            mappedItem[header] = item.impact_reason;
            break;
          case "document_name":
            mappedItem[header] = item.document_name;
            break;
          case "new_document_url":
            mappedItem[header] = item.new_document_url;
            break;
          default:
            mappedItem[header] = item[header];
        }
      });
      return mappedItem;
    });
  };

  const [searchQuery, setSearchQuery] = useState("");

  const [filteredData, setFilteredData] = useState<any[]>([]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const filteredDataMemo = useMemo(() => {
    return regulatoryTableData.filter((item: any) => {
      const changeData = JSON.parse(item.change);
      const searchFields = [
        changeData?.name,
        item.regulator,
        item.type,
        item.created_at,
        item.jurisdiction,
        item.impact_level,
        item.checked ? "Unreviewed" : "Reviewed",
      ];

      return searchFields.some((field) =>
        field?.toString().toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      );
    });
  }, [debouncedSearchQuery, regulatoryTableData]);

  useEffect(() => {
    setFilteredData(filteredDataMemo);
  }, [filteredDataMemo]);

  // const handlePageNoChange = (page: number) => {
  //   onPageNoChange(page);
  //   setPageNo(page);
  // }


  const headers = generateHeaders(regulatoryTableData);
  const rows = mapDataToHeaders(filteredData, headers);

  return (
    <div className="table-container">
      <Flex
        w= "100%"
        direction={isMobile ? "column" : "row"}
        position="relative"
        justifyContent="space-between"
        alignItems={isMobile ? "" : "center"}
        mb={4}
      >
        <SearchBox
          onSearch={handleSearch}
          w={isMediumScreen ? 500 : 900}
        />
        <PageSizeSelection
          options={pageSizeOptions}
          onPageSizeChange={(size: number) => onPageSizeChange(size)}
          w={200}
          ml={5}
          mr={0}
          mt={0}
        />
        <PageSelect
          currentPage={pageNo}
          totalPages={totalPages}
          loading={false}
          onPageChange={onPageNoChange}
          w={500}
          ml={5}
          mr={0}
          mt={10}
        />
      </Flex>
      <Flex overflowX={{ base: "scroll", md: "hidden" }}>
        <table
          style={{
            backgroundColor: tableBg,
            color: tableColor,
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              {headers
                .filter((header) => (header !== "id") && (header !== "impact_reason") && (header !== "document_name") && (header !== "new_document_url"))
                .map((header) => (
                  <th
                    key={header}
                    id={header}
                    style={{
                      backgroundColor: headerBg,
                      color: headerColor,
                      padding: "8px",
                    }}
                  >
                    <button
                      onClick={() => onFilter(header)}
                      style={{
                        background: "none",
                        border: "none",
                        color: headerColor,
                        cursor: "pointer",
                      }}
                    >
                      {header.charAt(0).toUpperCase() + header.slice(1)}
                    </button>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {regulatoryTableData.length === 0 && loading && (
              <Spinner
                size="xl"
                style={{
                  margin: "auto",
                  marginTop: "50px",
                  display: "block",
                }}
              />
            )}
            {regulatoryTableData.length === 0 && !loading && (
              <tr>
                <td colSpan={headers.length} className="no-data">
                  No regulatory change data available at present!
                </td>
              </tr>
            )}
            {regulatoryTableData.length > 0 && rows.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                onClick={() =>
                  handleRowClick(filteredData[rowIndex])
                }
                className="pointer"
              >
                {headers
                  .filter((header) => (header !== "id") && (header !== "impact_reason") && (header !== "document_name") && (header !== "new_document_url"))
                  .map((header) => (
                    <td
                      key={header}
                      className={loading ? "pulse" : ""}
                      style={{
                        padding: "8px",
                        borderBottom: `1px solid ${headerBg}`,
                      }}
                    >
                      {header === "Change" ? (
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: linkColor,
                          }}
                        >
                          {rows[rowIndex][header]} <BsLink style={{ marginLeft: '4px' }} />
                        </span>
                      ) : (
                        <span
                          style={{
                          color: tableColor,
                          }}
                        >
                          {header === "Date"
                          ? new Date(rows[rowIndex][header]).toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                            })
                          : header === "Type"
                          ? rows[rowIndex][header]
                            .toLowerCase()
                            .replace(/\b\w/g, (char: string) => char.toUpperCase())
                          : rows[rowIndex][header]}
                        </span>
                      )}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Flex>
      {/* {pageSize >= 50  ? (
        <PageSelect
          currentPage={pageNo}
          totalPages={totalPages}
          onPageChange={handlePageNoChange}
          loading={false}
        />
      ) : null} */}
    </div>
  );
};

export default RegulatoryTable;
