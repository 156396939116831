import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import { useAbortController } from "hooks/AbortControllerContext";
import { FeatureRoute } from "../../../models/routes";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/auth";
import { RootState } from "store/store";
import { useEffect, useState } from "react";
import eventBus from "../../../utils/eventBus";
import { setSubscription } from "../../../store/reducers/subscription";

export function SidebarLinks(props: { routes: FeatureRoute[] }) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");
  const { setAbortController } = useAbortController();
  const { getUserActiveSubscriptions } = useAuth();
  const { routes } = props;
  const [filteredRoutes, setFilteredRoutes] = useState<FeatureRoute[]>([]);
  const [subscriptionLevel, setSubscriptionLevel] = useState<string | null>(
    null
  );
  const newRegCount = useSelector(
    (state: RootState) => state.regChange.newRegsCount
  );
  const [newRegs, setNewRegs] = useState<number>(0);
  const profile: any = JSON.parse(
    localStorage.getItem("authentication") || "{}"
  );
  const userPlans = useSelector((state: RootState) => state.subscriptions.subscriptionPlans);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSubscriptionLevel = async () => {
      if (profile?.userId !== undefined) {
      let attempts = 0;
      const maxAttempts = 10;
      const interval = 1000; // 1 second

      const fetchLevel = async () => {
        attempts++;
        const level = await getUserActiveSubscriptions(profile.userId).then(
        (response) => {
          let alteredResponse = response;
          if (!response || response.length === 0) {
          alteredResponse = userPlans || [{ plan_name: "Basic" }];
          console.info("No subscriptions returned, falling back on storage or Basic plan.");
          }
          const highestLevelPlan = alteredResponse
          .filter((plan: { status: string }) => {
            return plan.status === "Active";
          })
          .reduce(
            (
            highest: { plan_name: string },
            current: { plan_name: string }
            ) => {
            const planOrder = ["Basic", "Premium", "Enterprise"];
            return planOrder.indexOf(current.plan_name) >
              planOrder.indexOf(highest.plan_name)
              ? current
              : highest;
            },
            { plan_name: "Basic" }
          );
          const updatedRoutes = routes.map((route: FeatureRoute) => {
          if (linkValidForSubscription(route, highestLevelPlan.plan_name)) {
            return route;
          }
          });
          setFilteredRoutes(
          updatedRoutes.filter((route) => route !== undefined)
          );
          dispatch(setSubscription(highestLevelPlan.plan_name));
          return highestLevelPlan.plan_name;
        }
        );

        if (!level && attempts < maxAttempts) {
        setTimeout(fetchLevel, interval);
        } else {
        sessionStorage.setItem("subscriptionLevel", level);
        setSubscriptionLevel(level);
        }
      };

      fetchLevel();
      } else {
      setFilteredRoutes(routes);
      setSubscriptionLevel(undefined);
      }
    };

    fetchSubscriptionLevel();
  }, [profile?.userId, userPlans]);

  useEffect(() => {
    setNewRegs(newRegCount);
  }, [newRegCount]);

  const handleResetRegChanges = () => {
    eventBus.emit('resetRegChanges');
  }

  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  const linkValidForSubscription = (
    route: FeatureRoute,
    subscriptionLevel: string
  ) => {
    switch (subscriptionLevel) {
      case "Basic":
        if (
          route.plan === "Basic" && route.showOnSidebar
        ) {
          return route;
        }
        break;
      case "Premium":
        if (
          (route.plan === "Basic" || route.plan === "Premium") &&
          route.showOnSidebar
        ) {
          return route;
        }
        break;
      case "Enterprise":
        if (
          (route.plan === "Basic" ||
            route.plan === "Premium" ||
            route.plan === "Enterprise") &&
          route.showOnSidebar
        ) {
          return route;
        }
        break;
      default:
        return route;
    }
  };

  const createLinks = (routes: FeatureRoute[]) => {
    if (subscriptionLevel === null) {
      return null; // or a loading indicator
    }
    return filteredRoutes.map((route: FeatureRoute, index: number) => {
      if (
        route.layout === "/compliance" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        if (route.name == "Institution Profile") {
          // console.info('Institution Profile');
          //return;
        }
        return (
          <NavLink
            key={index}
            to={route.layout + route.path}
            onClick={() => {
              const newAbortController = new AbortController();
              setAbortController(newAbortController);
              handleResetRegChanges();
            }}
          >
            {route.icon ? (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py="5px"
                  ps="0px"
                >
                  <Flex w="100%" alignItems="center" justifyContent="center">
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me="18px"
                    >
                      {route.icon}
                    </Box>
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                    >
                      {route.name}
                        {route.name === "Regulatory Change Review" && newRegs > 0
                        ? ` (${newRegs})`
                        : ""}
                    </Text>
                  </Flex>
                  <Box
                    h="36px"
                    w="4px"
                    bg={
                      activeRoute(route.path.toLowerCase())
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius="5px"
                  />
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py="5px"
                  ps="10px"
                >
                  <Text
                    me="auto"
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }
                  >
                    {route.name}
                  </Text>
                  <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
