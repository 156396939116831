import { configureStore } from '@reduxjs/toolkit';

import { authSlice } from './reducers/auth';
import { chatHistorySlice } from './reducers/chatHistory';
import { inputMessageSlice } from './reducers/inputMessage';
import { messagesSlice } from './reducers/messages';
import sessionSlice from './reducers/sessionSlice';
import { planSlice } from './reducers/plans';
import { userProductSlice } from './reducers/userProduct';
import { filesSlice } from './reducers/files';
import regChangeSlice from './reducers/regChange';
import subscriptionSlice from './reducers/subscription';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    product: userProductSlice.reducer,
    plan: planSlice.reducer,
    history: chatHistorySlice.reducer,
    inputMessage: inputMessageSlice.reducer,
    messages: messagesSlice.reducer,
    session: sessionSlice.reducer,
    files: filesSlice.reducer,
    regChange: regChangeSlice,
    subscriptions: subscriptionSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
