
import axios from 'axios';

export const getRegulatoryChanges = async (token: string, partner: string, persona: string, userId: number, pageNo: number, pageSize: number, filter?: string) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_LLM_API_URL}/get-regulatory-changes?partner=${partner}&persona=${persona}&user_id=${userId}&page=${pageNo}&limit=${pageSize}${filter ? `&filter=${filter}` : ''}`,
            {
                partner,
                persona,
                userId,
                page: pageNo,
                limit: pageSize,
                ...(filter && { filter }),
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return response.data; // Mock data
    } catch (error) {
        console.error('Error fetching regulatory changes:', error);
    }
}

export const getRegulatoryChangeList = async (token: string, partner: string, persona: string, user_id: number, pageNo: number, pageSize: number, filter?: string) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_LLM_API_URL}/get-regulatory-change-list`,
            {
                partner,
                persona,
                user_id,
                page: pageNo,
                limit: pageSize
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching regulatory changes:', error);
    }
}

export const getRegulatoryChangeDetail = async (token: string, id: number) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_LLM_API_URL}/get-regulatory-change-details`,
            { id },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching regulatory change detail:', error);
    }
}
    

export const setRegulatoryChangesCheckpoint = async (token: string, id: number) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_LLM_API_URL}/set-regulatory-changes-checkpoint?user_id=${id}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return response.data;
    } catch (error) {
        console.error('Error setting regulatory changes checkpoint:', error);
    }
}