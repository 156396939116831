import {
  CloseIcon,
  DeleteIcon,
  EditIcon,
  InfoOutlineIcon,
} from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import useAuth from 'hooks/auth';
import { useEffect, useRef, useState } from 'react';
import { IoMdImages } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import {
  reset,
  selectApprovedFiles,
  selectPendingApprovalFiles,
  updateApprovedFilesAtIndex,
  updatePendingApprovalFilesAtIndex,
} from '../../../store/reducers/files';
import './FileList.css';
import AdminPageSelect from '../../../components/pageSelect/adminPageSelect';
import { getPartners, getPersonas } from '../../../services/partnerService';
import { getApprovedFilesPaginated, getPendingFilesPaginated } from '../../../services/fileService';

const AdminFileList = () => {
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedPartnerName, setSelectedPartnerName] = useState(null);
  const [personas, setPersonas] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState<any | null>(null);
  const [options, setOptions] = useState([]);
  const [pendingFiles, setPendingFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [approvedLoading, setApprovedLoading] = useState(false);
  const [pendingLoading, setPendingLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState('');
  const [view, setView] = useState<'approved' | 'pending'>('approved');
  const toast = useToast();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { login } = useAuth();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const [showtitleTextbox, setShowTitleTextbox] = useState(false);
  const [title, setTitle] = useState('');
  const [showTextbox, setShowTextbox] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [showURLTextbox, setShowURLTextbox] = useState(false);
  const [sourceURL, setSourceURL] = useState('');
  const inputTextColor = useColorModeValue('black', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editedFileName, setEditedFileName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [fileToEdit, setFileToEdit] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [totalApprovedCount, setTotalApprovedCount] = useState(0);
  const [totalPendingCount, setTotalPendingCount] = useState(0);
  const [approvedPageNo, setApprovedPageNo] = useState(1);
  const [pendingPageNo, setPendingPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [group, setGroup] = useState('all');
  const [groups, setGroups] = useState<string[]>([]);
  const [approvedTotalPages, setApprovedTotalPages] = useState(0);
  const [pendingTotalPages, setPendingTotalPages] = useState(0);
  const approvedFiles = useSelector(selectApprovedFiles);
  const pendingApprovalFiles = useSelector(selectPendingApprovalFiles);
  const alreadyCalledAuthorizeAndGetOptionsRef = useRef(false);
  const alreadyCalledFetchPartnersRef = useRef(false);
  const alreadyCalledFetchPersonasRef = useRef(false);
  const { ensureValidToken } = useAuth();
  const dispatch = useDispatch();

  const handleSave = async () => {
    const token = await ensureValidToken(localStorage.getItem('token'));

    if (!editedFileName.trim()) {
      toast({
        title: 'Error',
        description: 'File name cannot be empty. Please provide a valid name.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    setIsSaving(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/update-file-name`,
        {
          partner: selectedPartnerName!,
          persona: selectedPersona.name,
          filename: fileToEdit.file_name,
          document_id: fileToEdit.id,
          newname: editedFileName,
          auth: profile?.user?.sub,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast({
          title: 'Update Successful',
          description: 'The file name has been successfully updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
        onClose();
      }
    } catch (error) {
      console.error('Error updating file name:', error);
      toast({
        title: 'Error',
        description:
          'An error occurred while updating the file name. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      dispatch(reset());
      alreadyCalledAuthorizeAndGetOptionsRef.current = false;
      setIsSaving(false);
      setFileToEdit(null);
      authorizeAndGetOptions(selectedPartnerName!, selectedPersona.name);
      fetchGroups(selectedPartnerName!, selectedPersona.name);
      setDisplayName('');
      setShowTextbox(false);
      setSourceURL('');
      setShowURLTextbox(false);
      setTitle('');
      setShowTitleTextbox(false);
      callFurtherPages(
        'approved',
        group,
        pageSize,
        1,
        selectedPartnerName!,
        selectedPersona.name
      );
      callFurtherPages(
        'pending',
        group,
        pageSize,
        1,
        selectedPartnerName!,
        selectedPersona.name
      );
    }
  };

  const handleEditFileName = (editedFile: any) => {
    setFileToEdit(editedFile);
    onOpen();
  };

  const fetchPartners = async () => {
    const token = await ensureValidToken(localStorage.getItem('token'));

    if (!alreadyCalledFetchPartnersRef.current) {
      alreadyCalledFetchPartnersRef.current = true;
      try {
        const response = await getPartners(token);
        const filteredPartners = response.data.filter(
          (partner: any) =>
            partner.name === 'state_banking_compliance' ||
            partner.name === 'federal_banking_compliance'
        );
        setPartners(filteredPartners);
      } catch (error) {
        console.error('Error fetching partners:', error);
        toast({
          title: 'Error',
          description: 'There was an error fetching partners.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    }
  };

  const fetchPersonas = async (partnerId: number) => {
    const token = await ensureValidToken(localStorage.getItem('token'));

    if (!alreadyCalledFetchPersonasRef.current) {
      alreadyCalledFetchPersonasRef.current = true;
      try {
        setLoading(true);
        const response = await getPersonas(token, partnerId);
        setPersonas(response);
      } catch (error) {
        console.error('Error fetching personas:', error);
        toast({
          title: 'Error',
          description: 'There was an error fetching personas.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const checkFilesAndToast = (
    apiResponse: any,
    noFilesTitle: string,
    noFilesDescription: string
  ): boolean => {
    if (!apiResponse.filenames || !apiResponse.filenames.length) {
      toast({
        title: noFilesTitle,
        description: noFilesDescription,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
        variant: 'subtle',
      });
      return false;
    }
    return true;
  };

  const callFurtherPages = async (
    fileType: string,
    pageGroup: any,
    currentPageSize?: number,
    pageNo?: number,
    partnerName?: string,
    personaName?: string
  ) => {
    const token = await ensureValidToken(localStorage.getItem('token'));
    if (fileType === 'approved') {
      
      setApprovedLoading(true);
      try {
        const approvedFilesAPIResponse = await getApprovedFilesPaginated(
          token,
          partnerName,
          personaName,
          pageNo || approvedPageNo,
          currentPageSize || pageSize,
          pageGroup
          
        );
        const approvedFilesFound = checkFilesAndToast(
          approvedFilesAPIResponse,
          'No Files Found',
          'No approved files were found for the selected partner and persona.'
        );
        if (!approvedFilesFound) {
          return;
        }
        const approvedFilenamesArray = transformFileData(
          approvedFilesAPIResponse.filenames
        );
        setTotalApprovedCount(approvedFilesAPIResponse.count_doc);
        storeFilesArray(approvedFilenamesArray, 'approved', pageNo);
      } catch (error) {
        console.error('Error fetching approved files:', error);
        toast({
          title: 'Fetch Error',
          description: 'Failed to fetch files. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
      } finally {
        setApprovedLoading(false);
      }
    } else {
      setPendingLoading(true);
      try {
        const pendingFilesAPIResponse = await getPendingFilesPaginated(
          token,
          partnerName,
          personaName,
          pendingPageNo,
          currentPageSize || pageSize,
          pageGroup
        );
        const pendingFilesFound = checkFilesAndToast(
          pendingFilesAPIResponse,
          'No Files Found',
          'No files pending approval were found for the selected partner and persona.'
        );
        if (!pendingFilesFound) {
          return;
        } else {
          setLoading(false);
        }
        const pendingFilenamesArray = transformFileData(
          pendingFilesAPIResponse.filenames
        );
        setTotalPendingCount(pendingFilesAPIResponse.count_doc);
        storeFilesArray(pendingFilenamesArray, 'pending', pageNo);
      } catch (error) {
        console.error('Error fetching pending files:', error);
        toast({
          title: 'Fetch Error',
          description: 'Failed to fetch files. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
      } finally {
        setPendingLoading(false);
      }
    }
  };

  const storeFilesArray = (
    files: any[],
    fileType: string,
    pageNumber: number
  ) => {
    if (fileType === 'approved') {
      const groupedApprovedFiles = groupFiles(files);
      setOptions(groupedApprovedFiles);
      dispatch(
        updateApprovedFilesAtIndex({
          index: pageNumber - 1,
          files: groupedApprovedFiles,
        })
      );
    } else {
      const groupedPendingFiles = groupFiles(files);
      setPendingFiles(groupedPendingFiles);
      dispatch(
        updatePendingApprovalFilesAtIndex({
          index: pageNumber - 1,
          files: groupedPendingFiles,
        })
      );
    }
  };

  const groupFiles = (files: any) => {
    return files.reduce((acc: Record<string, File[]>, file: any) => {
      const groupName = file.group_name || 'Uncategorized';
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(file);
      return acc;
    }, {});
  };

  type CustomFile = {
    id: any;
    file_name: any;
    upload_time: any;
    display_name: any;
    group_name: any;
    last_modified_time: any;
    is_approved: any;
    has_image: any;
  };

  const transformFileData = (files: any[]): CustomFile[] => {
    return files.map((file: any) => ({
      id: file.id,
      file_name: file.name,
      upload_time: file.upload_time,
      display_name: file.display_name || file.name,
      group_name: file.group_name,
      last_modified_time: file.last_modified_time,
      is_approved: file.is_approved,
      has_image: file.has_image,
    }));
  };

  const fetchGroups = async (partnerName: string, personaName: string) => {
    try {
      const token = await ensureValidToken(localStorage.getItem('token'));

      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/get-document-groups?partner=${partnerName}&persona=${personaName}`,
        null,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const fetchedGroups = response.data.data.map(
        (group: { group_name: string | null }) =>
          group.group_name === null ? 'all' : group.group_name
      );

      setGroups(fetchedGroups);
    } catch (error) {
      console.error('Error fetching groups:', error);
    }
  };

  const authorizeAndGetOptions = async (
    partnerName: string,
    personaName: string
  ) => {
    const token = await ensureValidToken(localStorage.getItem('token'));
    setLoadingOptions(true);
    if (!alreadyCalledAuthorizeAndGetOptionsRef.current) {
      alreadyCalledAuthorizeAndGetOptionsRef.current = true;
      try {
        const approvedFilesAPIResponse = await getApprovedFilesPaginated(
          token,
          partnerName,
          personaName,
          approvedPageNo,
          pageSize,
          group
 
        );
        const pendingFilesAPIResponse = await getPendingFilesPaginated(
          token,
          partnerName,
          personaName,
          pendingPageNo,
          pageSize,
          group
        );
        const pendingFilesFound = checkFilesAndToast(
          pendingFilesAPIResponse,
          'No Files Found',
          'No files pending approval were found for the selected partner and persona.'
        );

        const approvedFilesFound = checkFilesAndToast(
          approvedFilesAPIResponse,
          'No Files Found',
          'No approved files were found for the selected partner and persona.'
        );

        if (!pendingFilesFound || !approvedFilesFound) {
          return;
        }

        const approvedFilenamesArray = transformFileData(
          approvedFilesAPIResponse.filenames
        );
        setTotalApprovedCount(approvedFilesAPIResponse.count_doc);

        const pendingFilenamesArray = transformFileData(
          pendingFilesAPIResponse.filenames
        );

        setTotalPendingCount(pendingFilesAPIResponse.count_doc);
        storeFilesArray(approvedFilenamesArray, 'approved', approvedPageNo);
        storeFilesArray(pendingFilenamesArray, 'pending', pendingPageNo);
        updateTotalPages(
          approvedFilesAPIResponse.count_doc,
          pendingFilesAPIResponse.count_doc
        );
      } catch (error) {
        console.error('Error fetching options:', error);
        toast({
          title: 'Fetch Error',
          description: 'Failed to fetch files. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
      } finally {
        setLoadingOptions(false);
      }
    } else {
      console.info('Already called authorize and get options.');
      setOptions(approvedFiles[0]);
      setPendingFiles(pendingApprovalFiles[0]);
    }
  };

  const updateTotalPages = async (
    approvedFileCount: number,
    pendingFileCount: number
  ) => {
    const approvedTotalPages = Math.ceil(approvedFileCount / pageSize);
    const pendingTotalPages = Math.ceil(pendingFileCount / pageSize);
    setApprovedTotalPages(approvedTotalPages);
    setPendingTotalPages(pendingTotalPages);
  };

  const handleAddTitleClick = () => {
    setShowTitleTextbox(true);
  };

  const handleAddDisplayNameClick = () => {
    setShowTextbox(true);
  };

  const handleAddURLClick = () => {
    setShowURLTextbox(true);
  };

  const handleUpload = async () => {
    const token = await ensureValidToken(localStorage.getItem('token'));

    if (uploadedFile && selectedPartner && selectedPersona) {
      try {
        setUploading(true);

        const formData = new FormData();
        formData.append('pdf_file', uploadedFile);

        const additionalTitle =
          title && title.length > 0 ? JSON.stringify({ title }) : '';

        const response = await axios.post(
          `${
            process.env.REACT_APP_LLM_API_URL
          }/upload-file-to-azure?partner_name=${selectedPartnerName}&persona_name=${
            selectedPersona.name
          }&user_id=${profile?.userId}${
            displayName && displayName.length > 0
              ? `&display_name=${displayName}`
              : ''
          }${
            sourceURL && sourceURL.length > 0 ? `&source_url=${sourceURL}` : ''
          }${
            additionalTitle
              ? `&additional_title=${encodeURIComponent(additionalTitle)}`
              : ''
          }`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (response.data.success === true) {
          setUploadedFile(null);
          toast({
            title: 'Upload Successful',
            description: 'The file has been successfully uploaded.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
            variant: 'subtle',
          });
          dispatch(reset());
          alreadyCalledAuthorizeAndGetOptionsRef.current = false;
          authorizeAndGetOptions(selectedPartnerName!, selectedPersona.name);
          fetchGroups(selectedPartnerName!, selectedPersona.name);
          setSearchTerm('');
          setDisplayName('');
          setShowTextbox(false);
          setSourceURL('');
          setShowURLTextbox(false);
          setTitle('');
          setShowTitleTextbox(false);
        } else {
          toast({
            title: 'Upload Failed',
            description: `${
              response.data?.data
                ? ` ${response.data.data}`
                : ' Something went wrong.'
            }`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
            variant: 'subtle',
          });
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        toast({
          title: 'Upload Failed',
          description: 'Failed to upload the file. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
      } finally {
        setUploading(false);
      }
    } else {
      console.info('No file selected or no partner/persona selected.');
    }
  };

  const filteredApprovedFiles = Object.keys(options).reduce(
    (acc: any, group: any) => {
      acc[group] = options[group].filter(
        (file: any) =>
          file.display_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          file.file_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return acc;
    },
    {} as Record<string, any[]>
  );
  const filteredPendingFiles = Object.keys(pendingFiles).reduce(
    (acc: any, group: any) => {
      acc[group] = pendingFiles[group].filter(
        (file: any) =>
          file.display_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          file.file_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return acc;
    },
    {} as Record<string, any[]>
  );

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleOpenDeleteAlert = (fileName: string) => {
    setFileToDelete(fileName);
    setIsDeleteAlertOpen(true);
  };

  const handleCloseDeleteAlert = () => {
    setIsDeleteAlertOpen(false);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    await handleDeleteFile(fileToDelete);
    handleCloseDeleteAlert();
    setIsDeleting(false);  
  };

  const handleDeleteFile = async (fileName: string) => {
    const token = await ensureValidToken(localStorage.getItem('token'));
    alreadyCalledAuthorizeAndGetOptionsRef.current = false;
    try {
      const requestBody: any = {
        partner: selectedPartnerName,
        persona: selectedPersona.name,
        filename: fileName,
        auth: profile?.user?.sub,
      };
      const response = await axios.delete(
        `${process.env.REACT_APP_LLM_API_URL}/delete-file`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: requestBody,
        }
      );
      if (response.data.delete === true) {
        toast({
          title: 'File Deleted',
          description: `The file ${fileName} has been successfully deleted.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
        authorizeAndGetOptions(selectedPartnerName!, selectedPersona!.name);
        fetchGroups(selectedPartnerName!, selectedPersona!.name);
        setSearchTerm('');

        // fetchPendingFiles(selectedPartner);
      } else {
        toast({
          title: 'Delete Failed',
          description: 'Failed to delete the file. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      toast({
        title: 'Delete Failed',
        description: 'Failed to delete the file. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
        variant: 'subtle',
      });
    }
  };

  const handleFileClick = (fileName: string, displayName: string) => {
    alreadyCalledAuthorizeAndGetOptionsRef.current = false;
    const url = `/file/chunk/${selectedPartnerName}/${selectedPersona!.name}/${
      selectedPersona!.id
    }/${fileName}/${displayName}`;
    window.open(url, '_blank');
  };

  const handleApprovalFileClick = (
    fileName: string,
    displayName: string,
    documentId: number
  ) => {
    alreadyCalledAuthorizeAndGetOptionsRef!.current = false;
    const url = `/file/chunk-tags/${selectedPartnerName}/${selectedPersona.name}/${selectedPersona.id}/${fileName}/${displayName}/${documentId}`;
    window.open(url, '_blank');
  };

  const handleFileDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setUploadedFile(file);
  };

  const handlePersonaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    const selectedPersonaObj = personas.find(
      (persona: any) => persona.id.toString() === selectedId
    );
    alreadyCalledAuthorizeAndGetOptionsRef.current = false;
    if (selectedPersonaObj) {
      setSelectedPersona({
        id: selectedPersonaObj!.id,
        name: selectedPersonaObj!.name,
      });
      setView('approved');
      authorizeAndGetOptions(selectedPartnerName!, selectedPersonaObj!.name);
      fetchGroups(selectedPartnerName!, selectedPersonaObj!.name);
      setSearchTerm('');

      // Save to localStorage
      localStorage.setItem(
        'selectedPersona',
        JSON.stringify({
          id: selectedPersonaObj!.id,
          name: selectedPersonaObj!.name,
        })
      );
    }
  };

  const handleFileSelect = (e: any) => {
    const file = e.target.files[0];
    setUploadedFile(file);
  };

  const handlePartnerChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const partnerId = e.target.value;
    setSelectedPartner(partnerId);
    setSelectedPersona(null);

    const selectedPartnerObj = partners.find(
      (partner) => partner.id === parseInt(partnerId)
    );
    alreadyCalledAuthorizeAndGetOptionsRef!.current = false;
    if (selectedPartnerObj) {
      setSelectedPartnerName(selectedPartnerObj!.name);
      setLoading(true);
      await fetchPersonas(selectedPartnerObj!.id);

      // Save to localStorage
      localStorage.setItem(
        'selectedPartner',
        JSON.stringify({
          id: selectedPartnerObj!.id,
          name: selectedPartnerObj!.name,
        })
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    updateTotalPages(totalApprovedCount, totalPendingCount);
  }, [pageSize]);

  const ChangePageSize = () => {
    return (
      <FormControl mb={4}>
        <FormLabel htmlFor="page-size-select" fontWeight="bold">
          Page Size
        </FormLabel>
        <Select
          id="page-size-select"
          placeholder="Select page size"
          onChange={(e) => {
            const newSize = Number(e.target.value);
            setPageSize(newSize);
            callFurtherPages(
              'approved',
              group,
              newSize,
              1,
              selectedPartnerName!,
              selectedPersona.name
            );
            callFurtherPages(
              'pending',
              group,
              newSize,
              1,
              selectedPartnerName!,
              selectedPersona.name
            );
            setApprovedPageNo(1);
            setPendingPageNo(1);
          }}
          mb={4}
          value={pageSize}
        >
          {[25, 50, 75, 100].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </Select>
        <FormLabel htmlFor="group-select" fontWeight="bold">
          Select Group
        </FormLabel>
        <Select
          id="group-select"
          placeholder="Select group"
          onChange={(e) => {
            const selectedGroup = e.target.value;
            setGroup(selectedGroup);
            callFurtherPages(
              'approved',
              selectedGroup,
              pageSize,
              1,
              selectedPartnerName!,
              selectedPersona.name
            );
            callFurtherPages(
              'pending',
              selectedGroup,
              pageSize,
              1,
              selectedPartnerName!,
              selectedPersona.name
            );
            setApprovedPageNo(1);
            setPendingPageNo(1);
          }}
          mb={4}
          value={group}
        >
          {groups.map((group) => (
            <option key={group} value={group}>
              {group}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  };



  const handleOnPageChangeApproved = (page: number) => {
    setApprovedPageNo(page);
    if (approvedFiles[page - 1] != null || approvedFiles[page - 1] !== undefined) {
      setOptions(approvedFiles[page - 1]);
    } else {
      callFurtherPages(
        'approved',
        group,
        pageSize,
        page,
        selectedPartnerName!,
        selectedPersona.name
      )
    }
  }

  

  const handleOnPageChangePending = (page: number) => {
    setPendingPageNo(page);
    if (pendingApprovalFiles[page - 1] != null || pendingApprovalFiles[page - 1] !== undefined) {
      setPendingFiles(pendingApprovalFiles[page - 1]);
    } else {
      callFurtherPages(
        'pending',
        group,
        pageSize,
        page,
        selectedPartnerName!,
        selectedPersona.name
      );
    }
  }

  useEffect(() => {
    const initialize = async () => {
      const storedPartner = localStorage.getItem('selectedPartner');
      const storedPersona = localStorage.getItem('selectedPersona');
      if (storedPartner) {
        const parsedPartner = JSON.parse(storedPartner);
        setSelectedPartner(parsedPartner.id.toString());
        setSelectedPartnerName(parsedPartner.name);
        await fetchPersonas(parsedPartner.id);
        if (storedPersona) {
          const parsedPersona = JSON.parse(storedPersona);
          setSelectedPersona(parsedPersona);
          authorizeAndGetOptions(parsedPartner.name, parsedPersona.name);
          fetchGroups(parsedPartner.name, parsedPersona.name);
          setSearchTerm('');
        }
      }
    };
    initialize();
  }, []);

  useEffect(() => {
    if (!profile?.idToken || !profile?.userId) {
      login();
    }
    if (!profile?.isAdmin) {
      console.info('NOT ADMIN');
    } else {
      fetchPartners();
      console.info('ADMIN');
    }
    if (approvedFiles[0] === null) {
      alreadyCalledAuthorizeAndGetOptionsRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (selectedPartner && selectedPersona) {
      // alreadyCalledAuthorizeAndGetOptionsRef.current = false;
      authorizeAndGetOptions(selectedPartnerName!, selectedPersona.name);
      fetchGroups(selectedPartnerName!, selectedPersona.name);
    }
  }, [selectedPartner, selectedPersona]);

  return (
    <Box p={8} maxW="7xl" mx="auto" mt={{ xl: '50px' }}>
      {profile ? (
        !profile.isAdmin ? (
          <Flex justifyContent="center" alignItems="center" minH="200px">
            <Alert
              status="error"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
              maxW="400px"
              borderRadius="lg"
              boxShadow="lg"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={2} fontSize="lg">
                Access Denied
              </AlertTitle>
              <AlertDescription maxWidth="sm" mb={4}>
                You do not have permission to access this page.
              </AlertDescription>
              <Button
                colorScheme="blue"
                onClick={() => (window.location.href = '/')}
              >
                Go to Home
              </Button>
            </Alert>
          </Flex>
        ) : (
          <>
            <FormControl mb={4}>
              <FormLabel htmlFor="partner-select" fontWeight="bold">
                Select Partner
              </FormLabel>
              <Select
                id="partner-select"
                placeholder="Select partner"
                onChange={handlePartnerChange}
                mb={4}
                value={selectedPartner || ''}
              >
                {partners.map((partner) => (
                  <option key={partner.id} value={partner.id}>
                    {partner.display_name || partner.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            {selectedPartner && (
              <FormControl mb={4}>
                <FormLabel htmlFor="persona-select" fontWeight="bold">
                  Select Persona
                </FormLabel>
                <Select
                  id="persona-select"
                  placeholder="Select persona"
                  onChange={handlePersonaChange}
                  mb={4}
                  value={selectedPersona?.id || ''}
                  isDisabled={loading}
                >
                  {personas?.map((persona) => (
                    <option key={persona.id} value={persona.id}>
                      {persona.display_name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            {selectedPartner && selectedPersona && (
              <>
                <Tabs
                  index={view === 'approved' ? 0 : 1}
                  onChange={(index) =>
                    setView(index === 0 ? 'approved' : 'pending')
                  }
                >
                  <TabList px="25">
                    <Tab>Approved</Tab>
                    <Tab>Pending Approval</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>{/* Content for Approved Queue */}</TabPanel>
                    <TabPanel>{/* Content for Pending Approval */}</TabPanel>
                  </TabPanels>
                </Tabs>
                <Box px={0} py={2}>
                  <FormControl mb={4}>
                    <FormLabel htmlFor="search" fontWeight="bold">
                      Search Files
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="search"
                        placeholder="Search by file name"
                        value={searchTerm}
                        onChange={handleSearch}
                        color={inputTextColor}
                      />
                      {searchTerm && (
                        <InputRightElement>
                          <IconButton
                            size="sm"
                            aria-label="Clear search"
                            icon={<CloseIcon />}
                            onClick={() => setSearchTerm('')} // Clear the input
                          />
                        </InputRightElement>
                      )}
                    </InputGroup>
                  </FormControl>
                </Box>
                <ChangePageSize />

                {view === 'approved' && (
                  <>
                    {process.env.REACT_APP_NODE_ENV !== 'production' && (
                      <FormControl mb={4} ml={4}>
                        <FormLabel htmlFor="file-drop" fontWeight="bold">
                          Drop or Select a File
                        </FormLabel>
                        <Box
                          id="file-drop"
                          border="2px dashed"
                          borderRadius="10px"
                          borderColor="gray.300"
                          minH="250px"
                          h="100%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          onDrop={handleFileDrop}
                          onDragOver={(e) => e.preventDefault()}
                          cursor="pointer"
                        >
                          {uploadedFile ? (
                            <Box p={4}>
                              <Text fontWeight="bold">Selected File:</Text>
                              <Text>{uploadedFile.name}</Text>

                              {!showtitleTextbox && (
                                <Flex justifyContent="center" mt={3}>
                                  <Button
                                    colorScheme="blue"
                                    onClick={handleAddTitleClick}
                                    width="200px"
                                  >
                                    Add Title
                                  </Button>
                                </Flex>
                              )}

                              {showtitleTextbox && (
                                <Flex justifyContent="center" mt={3}>
                                  <Input
                                    placeholder="Enter Title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    width="200px"
                                    color={inputTextColor}
                                  />
                                </Flex>
                              )}

                              {!showTextbox && (
                                <Flex justifyContent="center" mt={3}>
                                  <Button
                                    colorScheme="blue"
                                    onClick={handleAddDisplayNameClick}
                                    width="200px"
                                  >
                                    Add Display Name
                                  </Button>
                                </Flex>
                              )}

                              {showTextbox && (
                                <Flex justifyContent="center" mt={3}>
                                  <Input
                                    placeholder="Enter Display Name"
                                    value={displayName}
                                    onChange={(e) =>
                                      setDisplayName(e.target.value)
                                    }
                                    width="200px"
                                    color={inputTextColor}
                                  />
                                </Flex>
                              )}

                              {!showURLTextbox && (
                                <Flex justifyContent="center" mt={3}>
                                  <Button
                                    colorScheme="blue"
                                    onClick={handleAddURLClick}
                                    width="200px"
                                  >
                                    Add Source URL
                                  </Button>
                                </Flex>
                              )}

                              {showURLTextbox && (
                                <Flex justifyContent="center" mt={3}>
                                  <Input
                                    placeholder="Enter Source URL"
                                    value={sourceURL}
                                    onChange={(e) =>
                                      setSourceURL(e.target.value)
                                    }
                                    width="200px"
                                    color={inputTextColor}
                                  />
                                </Flex>
                              )}

                              <Flex justifyContent="center" mt={3}>
                                {uploading ? ( // Show spinner if uploading
                                  <Spinner />
                                ) : (
                                  <Button
                                    colorScheme="blue"
                                    onClick={handleUpload}
                                  >
                                    Upload File to Index
                                  </Button>
                                )}
                              </Flex>
                            </Box>
                          ) : (
                            <Text>Select or drop a file here</Text>
                          )}
                        </Box>
                        <input
                          type="file"
                          accept=".txt,.pdf,.doc,.docx"
                          onChange={handleFileSelect}
                          style={{ display: 'none' }}
                        />
                      </FormControl>
                    )}

                    {loadingOptions ? (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        minH="200px"
                      >
                        <Spinner size="xl" />
                      </Flex>
                    ) : (
                      <>
                        <Text fontWeight="bold" mb={1} fontSize="lg">
                          Total Approved Files: {totalApprovedCount}
                        </Text>
                        <AdminPageSelect onPageChangeApproved={handleOnPageChangeApproved} onPageChangePending={handleOnPageChangePending} currentPageApproved={approvedPageNo} totalPagesApproved={approvedTotalPages} view={view} loading={approvedLoading}/>
                        {Object.keys(filteredApprovedFiles).length ? (
                          Object.keys(filteredApprovedFiles).map(
                            (group, groupIndex) => (
                              <Box
                                key={groupIndex}
                                mb={4}
                                className={approvedLoading ? 'pulse' : ''}
                              >
                                <Text fontWeight="bold" fontSize="md" mb={2}>
                                  {group}
                                </Text>
                                {filteredApprovedFiles[group].map(
                                  (file: any, fileIndex: any) => (
                                    <Flex
                                      key={fileIndex}
                                      alignItems="center"
                                      justifyContent="space-between"
                                      mb={2}
                                    >
                                      <Box
                                        onClick={() =>
                                          handleFileClick(
                                            file.file_name,
                                            file.display_name || file.file_name
                                          )
                                        }
                                        cursor="pointer"
                                        flex="1"
                                      >
                                        <Text>
                                          {file.display_name || file.file_name}
                                        </Text>
                                      </Box>
                                      <Flex alignItems="center">
                                        {file.last_modified_time && (
                                          <Text
                                            fontSize="sm"
                                            color="gray.500"
                                            mr={4}
                                          >
                                            Uploaded:{' '}
                                            {new Date(
                                              file.last_modified_time
                                            ).toLocaleString()}
                                          </Text>
                                        )}

                                        <Tooltip
                                          label="Image Available"
                                          aria-label="Image tooltip"
                                        >
                                          <Box
                                            as={
                                              file.has_image ? IoMdImages : Box
                                            } // Use Box as a placeholder when no image
                                            color={
                                              file.has_image
                                                ? 'white.500'
                                                : 'transparent'
                                            } // Make icon transparent if no image
                                            boxSize={{ base: 4, md: 5 }}
                                            mr={2}
                                            title={
                                              file.has_image
                                                ? 'Has Image'
                                                : 'No Image'
                                            }
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          label="Edit Name"
                                          aria-label="Edit tooltip"
                                        >
                                          <Box
                                            as={EditIcon}
                                            ml={2}
                                            color="white"
                                            cursor="pointer"
                                            onClick={() =>
                                              handleEditFileName(file)
                                            }
                                            boxSize={8}
                                            borderRadius="50%"
                                            p={2}
                                            _hover={{ bg: 'blue.600' }}
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          label="Show Details"
                                          aria-label="Show Details tooltip"
                                        >
                                          <Box
                                            as={InfoOutlineIcon}
                                            color="white"
                                            cursor="pointer"
                                            onClick={() =>
                                              handleFileClick(
                                                file.file_name,
                                                file.display_name ||
                                                  file.file_name
                                              )
                                            }
                                            boxSize={8}
                                            ml={2}
                                            borderRadius="50%"
                                            p={2}
                                            _hover={{ bg: 'blue.600' }}
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          label="Delete"
                                          aria-label="Delete tooltip"
                                          hasArrow
                                          bg="red.500"
                                        >
                                          <Box
                                            as={DeleteIcon}
                                            color="white"
                                            cursor="pointer"
                                            onClick={() =>
                                              handleOpenDeleteAlert(
                                                file.file_name
                                              )
                                            }
                                            ml={2}
                                            boxSize={8}
                                            borderRadius="50%"
                                            p={2}
                                            _hover={{ bg: 'red.600' }}
                                          />
                                        </Tooltip>
                                      </Flex>
                                    </Flex>
                                  )
                                )}
                              </Box>
                            )
                          )
                        ) : (
                          <Text>No approved files available</Text>
                        )}
                      </>
                    )}
                  </>
                )}

                {view === 'pending' && (
                  <>
                    {loadingOptions ? (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        minH="200px"
                      >
                        <Spinner size="xl" />
                      </Flex>
                    ) : (
                      <>
                        <Text fontWeight="bold" mb={1} fontSize="lg">
                          Total Pending Files: {totalPendingCount}
                        </Text>
                        <AdminPageSelect onPageChangeApproved={handleOnPageChangeApproved} onPageChangePending={handleOnPageChangePending} currentPagePending={pendingPageNo} totalPagesPending={pendingTotalPages} view={view} loading={pendingLoading}/>
                        {Object.keys(filteredPendingFiles).length ? (
                          Object.keys(filteredPendingFiles).map(
                            (group, groupIndex) => (
                              <Box
                                key={groupIndex}
                                mb={4}
                                className={pendingLoading ? 'pulse' : ''}
                              >
                                <Text fontWeight="bold" fontSize="md" mb={2}>
                                  {group}
                                </Text>
                                {filteredPendingFiles[group].map(
                                  (file: any, fileIndex: any) => (
                                    <Flex
                                      key={fileIndex}
                                      alignItems="center"
                                      justifyContent="space-between"
                                      mb={2}
                                    >
                                      <Box
                                        onClick={() =>
                                          handleApprovalFileClick(
                                            file.file_name,
                                            file.display_name || file.file_name,
                                            file.id
                                          )
                                        }
                                        cursor="pointer"
                                        flex="1"
                                      >
                                        <Text>
                                          {file.display_name || file.file_name}
                                        </Text>
                                      </Box>
                                      <Flex alignItems="center">
                                        {file.last_modified_time && (
                                          <Text
                                            fontSize="sm"
                                            color="gray.500"
                                            mr={4}
                                          >
                                            Uploaded:{' '}
                                            {new Date(
                                              file.last_modified_time
                                            ).toLocaleString()}
                                          </Text>
                                        )}

                                        <Tooltip
                                          label="Image Available"
                                          aria-label="Image tooltip"
                                        >
                                          <Box
                                            as={
                                              file.has_image ? IoMdImages : Box
                                            } // Use Box as a placeholder when no image
                                            color={
                                              file.has_image
                                                ? 'white.500'
                                                : 'transparent'
                                            } // Make icon transparent if no image
                                            boxSize={{ base: 4, md: 5 }}
                                            mr={2}
                                            title={
                                              file.has_image
                                                ? 'Has Image'
                                                : 'No Image'
                                            }
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          label="Edit Name"
                                          aria-label="Edit tooltip"
                                        >
                                          <Box
                                            as={EditIcon}
                                            ml={2}
                                            color="white"
                                            cursor="pointer"
                                            onClick={() =>
                                              handleEditFileName(file)
                                            }
                                            boxSize={8}
                                            borderRadius="50%"
                                            p={2}
                                            _hover={{ bg: 'blue.600' }}
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          label="Show Details"
                                          aria-label="Show Details tooltip"
                                        >
                                          <Box
                                            as={InfoOutlineIcon}
                                            color="white"
                                            cursor="pointer"
                                            onClick={() =>
                                              handleApprovalFileClick(
                                                file.file_name,
                                                file.display_name ||
                                                  file.file_name,
                                                file.id
                                              )
                                            }
                                            boxSize={8}
                                            ml={2}
                                            borderRadius="50%"
                                            p={2}
                                            _hover={{ bg: 'blue.600' }}
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          label="Delete"
                                          aria-label="Delete tooltip"
                                          hasArrow
                                          bg="red.500"
                                        >
                                          <Box
                                            as={DeleteIcon}
                                            color="white"
                                            cursor="pointer"
                                            onClick={() =>
                                              handleOpenDeleteAlert(
                                                file.file_name
                                              )
                                            }
                                            ml={2}
                                            boxSize={8}
                                            borderRadius="50%"
                                            p={2}
                                            _hover={{ bg: 'red.600' }}
                                          />
                                        </Tooltip>
                                      </Flex>
                                    </Flex>
                                  )
                                )}
                              </Box>
                            )
                          )
                        ) : (
                          <Text>No pending files available</Text>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )
      ) : null}
      {isDeleteAlertOpen && (
        <AlertDialog
          isOpen={isDeleteAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleCloseDeleteAlert}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete File
              </AlertDialogHeader>
              <AlertDialogBody>
                {isDeleting ? (
                  <Spinner size="md" />
                ) : (
                  `Are you sure you want to delete the file ${fileToDelete}?`
                )}
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={handleCloseDeleteAlert}
                  isDisabled={isDeleting} // Disable the cancel button during deletion
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={handleConfirmDelete}
                  ml={3}
                  isLoading={isDeleting} // Show loading spinner on the delete button
                  isDisabled={isDeleting} // Disable the delete button during deletion
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit File Name</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                color={inputTextColor}
                onChange={(e) => setEditedFileName(e.target.value)}
                placeholder="Enter new file name"
              />
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleSave}
                isLoading={isSaving}
              >
                Save
              </Button>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default AdminFileList;
