'use client';
/* eslint-disable */

import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Progress,
  Spinner,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import useAuth from 'hooks/auth';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { v4 as uuidv4 } from 'uuid';
import complianceData from './complianceData.json'; // Import the JSON data

import { CloseIcon, RepeatIcon } from '@chakra-ui/icons';
import { Image } from '@chakra-ui/react';
import axios from 'axios';
import { FaFileAlt, FaFilePdf, FaFileWord } from 'react-icons/fa';
import SEOComponent from 'seo';
import './EmailReview.css';
import ResetButton from '../../../../components/resetButton/resetButton';
export default function EmailReview() {
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [selectedCompliance, setSelectedCompliance] = useState<string[]>([]);
  const [complianceList, setComplianceList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [wsLoading, setWsLoading] = useState<boolean>(false);
  const [assistantResponse, setAssistantResponse] = useState<any>(null);
  const inputColor = useColorModeValue('navy.700', 'white');

  const textColor = useColorModeValue('navy.700', 'white');
  const placeholderColor = useColorModeValue(
    { color: 'gray.500' },
    { color: 'whiteAlpha.600' }
  );
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
  const toast = useToast();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const { login } = useAuth();
  const [ws, setWs] = useState<WebSocket | null>(null);
  const { ensureValidToken } = useAuth();

  // Handle file upload=====================================================================
  const [sessionId, setSessionId] = useState(null);
  const [sendButtonClicked, setSendButtonClicked] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);
  const [progressArray, setProgressArray] = useState<number[]>(
    Array(selectedFiles.length).fill(0) // Initialize array with 0 progress for each file
  );

  useEffect(() => {
    // Set the compliance list from the imported JSON data

    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }
    setComplianceList(complianceData);
  }, []);

  function removeEndMarker(input: string): string {
    const endMarker = '<END>';
    if (input.endsWith(endMarker)) {
      setSendButtonClicked(false);
      startNewSession();
      return input.substring(0, input.length - endMarker.length);
    }
    return input;
  }

  const handleReset = () => {
    setSubject('');
    setBody('');
    setSelectedFiles([]);
  }

  const handleSendWSMessage = () => {
    if (!ws) return;
    let intervalId: number | null = null;
    const bufferArray: string[] = [];

    ws.onmessage = (event) => {
      setLoading(false);
      const message = event.data;
      let cleanText = message;

      if (message.endsWith('<END>')) {
        cleanText = removeEndMarker(cleanText);
        setLoading(false);
        setWsLoading(false);
      }

      bufferArray.push(cleanText);

      if (intervalId === null) {
        intervalId = window.setInterval(() => {
          if (bufferArray.length > 0) {
            const textToRender = bufferArray.shift();
            setAssistantResponse(textToRender);
          } else {
            clearInterval(intervalId);
            intervalId = null;
          }
        }, 33); // Adjust the interval duration as needed
      }
    };
  };

  const handleReviewEmail = async () => {
    // const token = await ensureValidToken(localStorage.getItem('token'));

    if (!ws) return;
    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }

    if (!subject) {
      toast({
        title: 'Invalid Subject',
        description: 'Please enter your subject.',
        status: 'info',
        duration: 3000,
        isClosable: true,
        position: 'top',
        // variant: 'subtle',
      });
      return;
    }

    if (!body) {
      toast({
        title: 'Invalid Body',
        description: 'Please enter your body.',
        status: 'info',
        duration: 3000,
        isClosable: true,
        position: 'top',
        // variant: 'subtle',
      });
      return;
    }
    setAssistantResponse(null);
    setUploadingFiles([]);
    setSendButtonClicked(true);
    let fileUpload = false;
    if (selectedFiles.length > 0) {
      setWsLoading(true);
      fileUpload = true;
      await handleSendFiles();
      // setSendButtonClicked(false);
      // setSelectedFiles([]);
    }

    if (
      ws.readyState === WebSocket.CLOSING ||
      ws.readyState === WebSocket.CLOSED
    ) {
      console.warn(
        'WebSocket is closing or closed. Attempting to reconnect...'
      );
      // Here you could add logic to reconnect the WebSocket if necessary
      toast({
        title: 'WebSocket Disconnected',
        description: 'Reconnecting...',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });

      // Attempt to reconnect (ensure your connectWebSocket function handles reconnection properly)
      connectWebSocket();
      return;
    }
    setAssistantResponse(null);
    setLoading(true);
    setWsLoading(true);

    const selectedComplianceDetails = selectedCompliance.map((title) => {
      const compliance = complianceList.find((item) => item.title === title);
      return {
        title: compliance.title,
        descriptions: compliance.descriptions,
      };
    });
    const token = await ensureValidToken(null);

    const requestBody = {
      is_from_user_document: fileUpload,
      partner_id: process.env.REACT_APP_PARTNER_ID,
      partner: process.env.REACT_APP_PARTNER,
      persona: process.env.REACT_APP_EMAIL_REVIEW_PERSONA,
      UUId: uuidv4(),
      question: subject + '\n\n' + body,
      checklist_array: selectedComplianceDetails,
      user_id: profile?.userId,
      session_id: sessionId,
      access_token: token,
    };

    console.info('Calling API with:', requestBody);

    ws.send(JSON.stringify(requestBody));
    handleSendWSMessage();
  };

  const handleSubjectChange = (event: any) => setSubject(event.target.value);
  const handleBodyChange = (event: any) => setBody(event.target.value);
  const handleComplianceChange = (value: string[]) =>
    setSelectedCompliance(value);

  const handleSelectAll = () =>
    setSelectedCompliance(complianceList.map((item) => item.title));
  const handleRemoveAll = () => setSelectedCompliance([]);

  const connectWebSocket = () => {
    const newWs = new WebSocket(
      `${process.env.REACT_APP_LLM_WS_URL}/ws-get-email-review`
    );
    newWs.onopen = () => {
      console.info('WebSocket connected');
      setWs(newWs);
    };
    newWs.onclose = () => {
      console.info('WebSocket disconnected');
    };

    newWs.onerror = (error) => {
      console.error('WebSocket error:', error);
      toast({
        title: 'Error connecting websocket',
        description: 'Failed to connect websocket. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
        // variant: 'subtle',
      });
    };

    return () => {
      // Close WebSocket connection on component unmount
      if (ws) {
        ws.close();
      }
    };
  };

  useEffect(() => {
    if (!ws) {
      connectWebSocket();
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && !ws) {
        console.info('visible  ============');
        connectWebSocket();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [ws]);

  const startNewSession = () => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
  };

  useEffect(() => {
    startNewSession();
  }, []);

  const [isDragging, setIsDragging] = useState(false); // Track dragging

  const maxTotalSize = 1 * 1024 * 1024;

  const handleFileChange = (newFiles: FileList | File[]) => {
    // Check if a file upload is already in progress
    if (loading) {
      toast({
        title: 'File Upload in Progress',
        description: 'Please wait until the current file upload is completed.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      return;
    }

    const filesArray = Array.from(newFiles);

    const currentTotalSize = selectedFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );
    const newTotalSize = filesArray.reduce(
      (acc, file) => acc + file.size,
      currentTotalSize
    );

    // Check if the total file size exceeds the max limit
    if (newTotalSize > maxTotalSize) {
      toast({
        title: 'File Size Limit Exceeded',
        description: `Total file size exceeds 1 MB. Please select smaller files.`,
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      return;
    }

    // Allowed file types: .pdf, .doc, .docx, .txt
    const allowedTypes = [
      'application/pdf',
      // 'application/msword', // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'message/rfc822', // .eml
      // 'text/plain', // .txt
    ];

    // Filter valid files based on the allowed types
    const validFiles = filesArray.filter((file) =>
      allowedTypes.includes(file.type)
    );

    // Check if there are invalid files
    if (validFiles.length !== filesArray.length) {
      toast({
        title: 'Invalid File Type',
        description: 'Only PDF, DOCX and EML files are allowed.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    if (validFiles.length > 5) {
      toast({
        title: 'File Upload Limit Exceeded',
        description:
          'You can only upload a maximum of 5 files at a time. Please remove some files and try again.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      return;
    }

    // Add the valid files to the selected files
    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleRemoveFile = (fileName: string) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  // Function to handle drag-over event
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  // Function to handle drag-leave event
  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(false);
  };

  // Function to handle the drop event
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (selectedFiles.length >= 5) {
      toast({
        title: 'File Upload Limit Exceeded',
        description:
          'You can only upload a maximum of 5 files at a time. Please remove some files and try again.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    event.preventDefault();
    const files = event.dataTransfer.files;
    setIsDragging(false);
    handleFileChange(files);
  };

  const handleSendFiles = async () => {
    setLoading(true);

    if (selectedFiles.length > 0) {
      setSelectedFiles([]);
      setUploadingFiles(selectedFiles);
      for (let index = 0; index < selectedFiles.length; index++) {
        const element = selectedFiles[index];
        let stopSimulation: (() => void) | null = null;

        try {
          setProgressForFile(index, 0); // Start progress at 0%

          const fileUpload = await handleUpload(element, (actualProgress) => {
            if (stopSimulation) stopSimulation();
            stopSimulation = simulateSlowerProgress(
              actualProgress,
              (progress) => {
                setProgressForFile(index, progress);
              }
            );
          });

          if (fileUpload) {
            if (stopSimulation) stopSimulation();
            setProgressForFile(index, 100); // Set to 100% only on successful upload
          } else {
            if (stopSimulation) stopSimulation();
            setProgressForFile(index, 0); // Reset progress on failure
            console.error(`File ${element.name} failed to upload.`);
            break; // Stop processing further files on failure
          }
        } catch (error) {
          if (stopSimulation) stopSimulation();
          setProgressForFile(index, 0); // Reset progress on error
          console.error(`Error uploading file ${element.name}:`, error);
          break; // Stop processing further files on error
        }

        // Wait for a short delay before starting the next file upload
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }

    setLoading(false);
  };

  const simulateSlowerProgress = (
    actualProgress: number,
    setProgress: (value: number) => void
  ) => {
    let currentProgress = 0;
    const interval = setInterval(() => {
      if (currentProgress < Math.min(actualProgress * 90, 90)) {
        currentProgress += 1;
        setProgress(currentProgress);
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust this value to make the animation faster or slower
    return () => clearInterval(interval);
  };

  const handleUpload = async (
    file: File,
    onProgress: (progress: number) => void
  ) => {
    if (file) {
      try {
        const formData = new FormData();
        formData.append('file', file);

        const token = await ensureValidToken(localStorage.getItem('token'));

        // Determine the API endpoint based on the file type
        const isEMLFile =
          file.type === 'message/rfc822' || file.name.endsWith('.eml');
        const apiEndpoint = isEMLFile
          ? `${process.env.REACT_APP_LLM_API_URL}/upload-user-eml-file-to-azure?&user_id=${profile?.userId}&session_id=${sessionId}`
          : `${process.env.REACT_APP_LLM_API_URL}/upload-user-file-to-azure?&user_id=${profile?.userId}&session_id=${sessionId}`;

        const response = await axios.post(apiEndpoint, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = progressEvent.total
              ? progressEvent.loaded / progressEvent.total
              : 0;
            onProgress(percentCompleted);
          },
        });

        console.info('File upload response:', response.data);

        if (response.data.success === true) {
          return true;
        } else {
          toast({
            title: 'Upload Failed',
            description: `${
              response.data?.data
                ? ` ${response.data.data}`
                : ' Something went wrong.'
            }`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
            variant: 'subtle',
          });
          return false;
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        toast({
          title: 'Upload Failed',
          description: 'Failed to upload the file. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
        return false;
      }
    } else {
      console.info('No file selected or no partner/persona selected.');
      return false;
    }
  };

  const setProgressForFile = (index: number, value: number) => {
    setProgressArray((prevProgress) => {
      const updatedProgress = [...prevProgress];
      updatedProgress[index] = value;
      return updatedProgress;
    });
  };

  return (
    <>
      <SEOComponent
        title="Nucomply-Email Review"
        description="Banking Compliance Expert Email Review"
        canonical="/compliance/email-review"
      />
      <div style={{ marginTop: '100px' }}>
        <Flex
          w="100%"
          direction="column"
          position="relative"
          mt={{ base: '70px', md: '0px', xl: '0px' }}
        >
          <Flex
            mx="auto"
            w={{ base: '100%', md: '100%', xl: '100%' }}
            maxW="100%"
            justify="center"
            direction={{ base: 'column', md: 'row' }}
          >
            <Card
              border={isDragging && '1px dashed'}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              minW={{ base: '100%', md: '40%', xl: '476px' }}
              maxW={{ base: '100%', md: '40%', xl: '476px' }}
              h="min-content"
              me={{ base: '0px', md: '20px' }}
              mb={{ base: '20px', md: '0px' }}
            >
              <FormLabel
                display="flex"
                ms="10px"
                htmlFor={'subject'}
                fontSize="md"
                color={textColor}
                letterSpacing="0px"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
              >
                Subject <ResetButton handleReset={handleReset}/>
              </FormLabel>
              <Input
                value={subject}
                color={textColor}
                border="1px solid"
                borderRadius={'10px'}
                borderColor={borderColor}
                h="60px"
                id="subject"
                fontWeight="500"
                placeholder="Type the subject here..."
                _placeholder={placeholderColor}
                _focus={{ borderColor: 'none' }}
                mb="28px"
                onChange={handleSubjectChange}
              />
              <FormLabel
                display="flex"
                ms="10px"
                htmlFor={'body'}
                fontSize="md"
                color={textColor}
                letterSpacing="0px"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
              >
                Body
              </FormLabel>

              <Flex
                w="100%"
                mb="15px"
                flexDirection="column"
                position="relative"
                border="1px solid"
                borderColor={borderColor}
                borderRadius="10px"
                pt="10px"
                pb="0"
                px="0"
                _focusWithin={{
                  borderColor: 'gray.400',
                  boxShadow: '0 0 0 2px blue.500',
                }}
              >
                {/* Display Selected Files */}
                {!sendButtonClicked && selectedFiles.length > 0 && (
                  <Flex
                    p={{ base: '2px', md: '5px' }}
                    gap="5px"
                    wrap="wrap"
                    ms="2"
                  >
                    {selectedFiles.map((file: any, index: any) => (
                      <Flex
                        key={index}
                        justifyContent="space-between"
                        alignItems="center"
                        mb="4px"
                        width={{ base: '160px', md: '200px' }}
                        p="10px"
                        border="1px solid"
                        borderColor="gray.500"
                        borderRadius="md"
                        position="relative"
                      >
                        <Box display="flex" alignItems="center">
                          {/* Display appropriate icons based on file type */}
                          <Box mr="8px">
                            {file.type === 'application/pdf' && (
                              <Icon as={FaFilePdf} color="white" w={6} h={6} />
                            )}
                            {(file.type === 'application/msword' ||
                              file.type ===
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && (
                              <Icon as={FaFileWord} color="white" w={6} h={6} />
                            )}
                            {file.type === 'text/plain' && (
                              <Icon as={FaFileAlt} color="white" w={6} h={6} />
                            )}
                            {file.type === 'message/rfc822' && (
                              <Image
                                w={6}
                                h={6}
                                src={'/img/solutions/emlFile.png'}
                                alt="logo"
                              />
                            )}
                          </Box>
                          <Box>
                            <Text
                              fontSize="sm"
                              color={inputColor}
                              isTruncated
                              maxW={{ base: '90px', md: '120px' }}
                            >
                              {file.name}
                            </Text>
                            <Text fontSize="sm" color={inputColor}>
                              {/* Display file type in uppercase */}
                              {file.type === 'application/pdf'
                                ? 'PDF'
                                : file.type === 'application/msword'
                                ? 'DOC'
                                : file.type ===
                                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                ? 'DOCX'
                                : file.type === 'text/plain'
                                ? 'TXT'
                                : file.type.toUpperCase()}
                            </Text>
                          </Box>
                        </Box>
                        <IconButton
                          size="xs"
                          aria-label="Remove file"
                          icon={<CloseIcon />}
                          onClick={() => handleRemoveFile(file.name)}
                          position="absolute"
                          top="2px"
                          right="2px"
                        />
                      </Flex>
                    ))}
                  </Flex>
                )}

                <Flex position="relative">
                  <Textarea
                    value={body}
                    border="0px solid"
                    borderTop="none"
                    borderBottomRadius="10px"
                    borderColor={borderColor}
                    p="0px 15px"
                    minH="110px"
                    fontWeight="500"
                    _focus={{ borderColor: 'none' }}
                    color={textColor}
                    placeholder={
                      selectedFiles?.length > 0 ? '' : 'Enter the body here...'
                    }
                    _placeholder={placeholderColor}
                    id="body"
                    onChange={handleBodyChange}
                  />
                </Flex>

              </Flex>
              <FormLabel
                display="flex"
                ms="10px"
                htmlFor="file-upload"
                fontSize="md"
                color={textColor}
                letterSpacing="0px"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
              >
                Upload Files
              </FormLabel>
              <Input
                type="file"
                id="file-upload"
                multiple
                onChange={(e) => handleFileChange(e.target.files)}
                border="1px solid"
                borderRadius={'10px'}
                borderColor={borderColor}
                h="60px"
                mb="10px"
                color={textColor}
                display="none" // Hide the input element
              />

              {/* Custom button for triggering the file input */}
              <Flex>
                <Button
                  as="label"
                  htmlFor="file-upload"
                  cursor="pointer"
                  backgroundColor="gray.700"
                  color="white"
                  _hover={{ backgroundColor: 'gray.600' }}
                  mb="20px"
                  borderRadius="10px"
                  p="10px 20px"
                >
                  Select Files
                </Button>
                <Flex mt="10px" ml="5px">
                  {selectedFiles.length > 0 ? (
                    <Text color={textColor} fontSize="sm">
                      {selectedFiles.length} files selected
                    </Text>
                  ) : (
                    <Text color={textColor} fontSize="sm">
                      No file selected
                    </Text>
                  )}
                </Flex>
              </Flex>

              <FormLabel
                display="flex"
                ms="10px"
                htmlFor="complianceConcerns"
                fontSize="md"
                color={textColor}
                letterSpacing="0px"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
              >
                Compliance Concerns
              </FormLabel>
              <Flex justifyContent="space-between" mb="10px">
                <Button size="sm" onClick={handleSelectAll}>
                  Select All
                </Button>
                <Button size="sm" onClick={handleRemoveAll}>
                  Remove All
                </Button>
              </Flex>
              <CheckboxGroup
                value={selectedCompliance}
                onChange={handleComplianceChange}
              >
                <Flex wrap="wrap" gap="10px">
                  {complianceList.map((compliance, index) => (
                    <Checkbox
                      key={index}
                      value={compliance.title}
                      colorScheme="purple"
                      fontWeight="500"
                    >
                      {compliance.title}
                    </Checkbox>
                  ))}
                </Flex>
              </CheckboxGroup>

              <Button
                py="20px"
                px="16px"
                fontSize="md"
                variant="primary"
                borderRadius="45px"
                w={{ base: '100%' }}
                mt="28px"
                h="54px"
                onClick={handleReviewEmail}
                isLoading={loading || wsLoading ? true : false}
                _hover={{
                  boxShadow:
                    '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
                  bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%) !important',
                  _disabled: {
                    bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)',
                  },
                }}
              >
                Review Email
              </Button>
            </Card>
            <Card maxW="100%" h="100%">
              <Flex>
                <Text
                  fontSize={'30px'}
                  color={textColor}
                  fontWeight="800"
                  mb="10px"
                >
                  Email Output
                </Text>
              </Flex>

              <Flex>
                {uploadingFiles.length > 0 && (
                  <Flex w="100%" align="top" my="10px">
                    <Flex flexDirection="column" flex="1">
                      {/* Display Selected Files=================================== */}

                      <Flex
                        p={{ base: '2px', md: '5px' }}
                        gap="5px"
                        wrap="wrap"
                      >
                        {uploadingFiles.map((file: any, index: any) => (
                          <Flex
                            key={index}
                            justifyContent="space-between"
                            alignItems="center"
                            mb="4px"
                            width={{ base: '180px', md: '220px' }}
                            p="10px"
                            border="1px solid"
                            borderColor="gray.500"
                            borderRadius="md"
                            position="relative"
                            flexDirection="column" // Make space for progress bar
                          >
                            <Box display="flex" alignItems="center">
                              <Box mr="8px">
                                {file.type === 'application/pdf' && (
                                  <Icon
                                    as={FaFilePdf}
                                    color="white"
                                    w={6}
                                    h={6}
                                  />
                                )}
                                {(file.type === 'application/msword' ||
                                  file.type ===
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && (
                                  <Icon
                                    as={FaFileWord}
                                    color="white"
                                    w={6}
                                    h={6}
                                  />
                                )}
                                {file.type === 'text/plain' && (
                                  <Icon
                                    as={FaFileAlt}
                                    color="white"
                                    w={6}
                                    h={6}
                                  />
                                )}

                                {file.type === 'message/rfc822' && (
                                  <Image
                                    w={6}
                                    h={6}
                                    src={'/img/solutions/emlFile.png'}
                                    alt="logo"
                                  />
                                )}
                              </Box>
                              <Box>
                                <Text
                                  fontSize="sm"
                                  color={inputColor}
                                  isTruncated
                                  maxW={{ base: '100px', md: '130px' }}
                                >
                                  {file.name}
                                </Text>
                                <Text fontSize="sm" color={inputColor}>
                                  {file.type === 'application/pdf'
                                    ? 'PDF'
                                    : file.type === 'application/msword'
                                    ? 'DOC'
                                    : file.type ===
                                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                    ? 'DOCX'
                                    : file.type === 'text/plain'
                                    ? 'TXT'
                                    : file.type.toUpperCase()}
                                </Text>
                              </Box>
                            </Box>
                            {/* Progress Bar */}
                            <Progress
                              value={progressArray[index]} // Dynamic progress for each file
                              size="sm"
                              colorScheme="white"
                              mt="8px"
                              width="100%"
                              height="4px"
                            />
                          </Flex>
                        ))}
                      </Flex>

                      {/* Display Selected Files=================================== */}
                    </Flex>
                  </Flex>
                )}
              </Flex>

              <Flex ml={6} justifyContent="flex-start">
                {assistantResponse && (
                  <ReactMarkdown className="font-medium markdown-content">
                    {assistantResponse
                      .replace(/```markdown/g, '')
                      .replace(/```/g, '')}
                  </ReactMarkdown>
                )}
              </Flex>

              {loading && (
                <Flex justifyContent="center" alignItems="center">
                  <Spinner size="xl" />
                </Flex>
              )}
              <div>
                <Text color={textColor}>{/* Render API response here */}</Text>
              </div>
            </Card>
          </Flex>
        </Flex>
        {/* Add your PremiumModal component if needed */}
        {/* <PremiumModal /> */}
      </div>
    </>
  );
}
