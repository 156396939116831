import React from 'react';
import PageSelect from './pageSelect';

interface AdminPageSelectProps {
    currentPageApproved?: number;
    totalPagesApproved?: number;
    currentPagePending?: number;
    totalPagesPending?: number;
    view: string;
    loading: boolean;
    onPageChangeApproved: (page: number) => void;
    onPageChangePending: (page: number) => void;
}

const AdminPageSelect: React.FC<AdminPageSelectProps> = (props) => {
    const handlePageChange = (pageNo: number) => {
        if (props.view === 'approved') {
            props.onPageChangeApproved(pageNo);
        }
        else if (props.view === 'pending') {
            props.onPageChangePending(pageNo);
        }
    }
    return (
        <>
            {props.view === 'approved' && (
                <PageSelect currentPage={props.currentPageApproved!} totalPages={props.totalPagesApproved!} onPageChange={handlePageChange} loading={props.loading} />
            )}
            {props.view === 'pending' && (
                <PageSelect currentPage={props.currentPagePending!} totalPages={props.totalPagesPending!} onPageChange={handlePageChange} loading={props.loading}/>
            )}
        </>
    );
};

export default AdminPageSelect;